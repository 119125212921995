export const SERVER = "https://api.dsaos.com";
export const API_URL = "https://api.dsaos.com/graphql";
export const SOCKET_URL = "wss://api.dsaos.com/graphql";
export const CLOUD_URL = "https://dsaos.sgp1.digitaloceanspaces.com/PRO/"

// export const SERVER = "http://192.168.0.154:5000";
// export const API_URL = "http://192.168.0.154:5000/graphql";
// export const SOCKET_URL = "ws://192.168.0.154:5000/graphql";
// export const CLOUD_URL = "https://dsaos.sgp1.digitaloceanspaces.com/DEV/";

// export const SERVER = "https://daa5-2405-201-200c-9186-2c60-4be3-6865-d908.in.ngrok.io";
// export const API_URL = "https://daa5-2405-201-200c-9186-2c60-4be3-6865-d908.in.ngrok.io/graphql";
// export const SOCKET_URL = "ws://daa5-2405-201-200c-9186-2c60-4be3-6865-d908.in.ngrok.io/graphql";
// export const CLOUD_URL = "https://dsaos.sgp1.digitaloceanspaces.com/DEV/";


export const WEB_URL = "https://dsaos.com";
