// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import authentication from './authentication'
import auth from './reducer/auth'

const rootReducer = {
  auth,
  navbar,
  layout,
  authentication
}

export default rootReducer
