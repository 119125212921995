const initialState = {
  userData: JSON.parse(localStorage.getItem("userData")),
  branchId: localStorage.getItem("branchId") || "",
  bankId: localStorage.getItem("bankId") || "",
  dsaId: localStorage.getItem("dsaId") || "",
  selectedColumn: JSON.parse(localStorage.getItem("selectedColumn"))||[]
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, userData: action.data?.user };
    case "UPDATE_PROFILE":
      return { ...state, userData: action.data };
    case "UPDATE_TWOFA":
      return { ...state, userData: action.data };
    case "LOGOUT":
      return { ...state, userData: {},bankId:null };
    case "ACTIVE_BRANCH":
      return { ...state, branchId: action?.data };
    case "ACTIVE_BANK":
      return { ...state, bankId: action?.data };
    case "ACTIVE_DSA":
      return { ...state, dsaId: action?.data };
    case "SET_COLUMN":
      return {...state,selectedColumn:action?.data};
    default:
      return state;
  }
};

export default authReducer;
